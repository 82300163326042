import about from "./aboutInfo";
import * as a from "./products.json";
import {
  HistoryOutlined,
  DoubleRightOutlined,
  TeamOutlined,
  ThunderboltOutlined,
  LikeOutlined,
  GoldOutlined,
  FieldTimeOutlined,
  DollarCircleOutlined,
} from "@ant-design/icons";

var products = a.products.map((e, i) => {
  return e.products.map((p, j) => {
    return {
      name: p.name,
      url: `/product/${e.id}/${p.name}`,
      img: p.img,
    };
  });
});
products = [].concat.apply([], products);
export default {
  background: [
    "//v4-upload.goalsites.com/312/image_1602466484_banenr-4.jpg",
    "//v4-upload.goalsites.com/312/image_1602466500_banner-5.jpg",
    "//v4-upload.goalsites.com/312/image_1599117418_yonglian-3.jpg",
    "//v4-upload.goalsites.com/312/image_1599117424_yonglian-4.jpg",
  ],
  section1: {
    heading: about.aboutCompany.section1.heading,
    image: about.aboutCompany.section1.image,
    text: [
      "Guangdong Yonglisn CNC Equipment Technology Co., LTD. (YLSK) is a leading industry specialized in Design & Manufacturing of CNC Spring machinery and their accessories.",
      " Located in Dongguan City, Guangdong Province, China. Our existing machine categories are Compression Coiling Spring Machines, Forming machines, Cam-less Spring Machine & Wire Bending Machines,",
      " Moreover we produce Spring quality testing machines & Heating Furnaces. Our equipment is widely used in products such as compression spring, tension spring, torsion spring, wire forming & wire bending products, also special purpose springs, Which can meet the production requirements of many Automobile, Electronic, Toy, Sports equipment, Household appliance, and so on.",
    ],
  },
  section2: [
    {
      heading: "Young Team",
      text:
        "The company have a young and energetic team, and providing uninterrupted service to customers in 24 hours.",
    },
    {
      heading: "Rapid Development",
      text:
        "YLSK wishes rapid development with constant commitment, high quality and stable products.",
    },
    {
      heading: "Advanced Technologies",
      text:
        "Converting advanced technologies into productive forces requires advanced production and processing capabilities. ",
    },
  ],
  section3: {
    heading: "Our Services",
    content: [
      {
        heading: "Experienced",
        icon: <HistoryOutlined />,
        text: "Vast industry experience and deep knowledge of the field.",
      },
      {
        heading: "Advanced Equipment",
        icon: <DoubleRightOutlined />,
        text:
          "Rugged and sturdy infrastructure outfitted with latest designed machinery and equipment's.",
      },
      {
        heading: "Professional Worker",
        icon: <TeamOutlined />,
        text:
          "Productive and experienced professionals comprising the workforce.",
      },
      {
        heading: "Advanced Technology",
        icon: <ThunderboltOutlined />,
        text:
          "Technology driven manufacturing techniques and working methodologies.",
      },
      {
        heading: "High Quality",
        icon: <LikeOutlined />,
        text: "Strict and stringent quality control system.",
      },
      {
        heading: "Gigantic Stock",
        icon: <GoldOutlined />,
        text:
          "Gigantic stock of machines to serve emergency demands and supply.",
      },
      {
        heading: "High Efficiency",
        icon: <FieldTimeOutlined />,
        text: "Active transportation and packaging department.",
      },
      {
        heading: "Competitive Price",
        icon: <DollarCircleOutlined />,
        text: "Quality Machines at competitive price.",
      },
    ],
  },
  section4: {
    heading: "Our Products",
    content: products,
  },
  section5: {
    heading: "Our Experts",
    img: [
      "//v4-upload.goalsites.com/312/image_1564207443__B1A4968.JPG",
      "//v4-upload.goalsites.com/312/image_1564207457__B1A4975.JPG",
      "//v4-upload.goalsites.com/312/image_1564207471__B1A4973.JPG",
      "//v4-upload.goalsites.com/312/image_1564207479_D37A9285.JPG",
    ],
  },
  section6: {
    heading: "Joint Venture",
    text:
      "One more step for developing Successful Business towards global market. YLSK CHINA & ARSWAN INDIA successfully signed Joint Venture business.",
    img: ["https://firebasestorage.googleapis.com/v0/b/arswan-web-alpha.appspot.com/o/0001.jpg?alt=media&token=fb0eeff4-fcf7-4c84-8fab-bb6743589856"
    ],
  },
};
