import React from "react";
import { Row, Col } from "antd";
import about from "../../utils/aboutInfo";
import HeadingSection from "../common/headingSection/HeadingSection";
import InfoComponentWithImage from "./../common/InfoComponentWithImage/InfoComponentWithImage";
import HeadingText from "./../common/HeadingText/HeadingText";
const AboutStrength = () => {
  const { aboutStrength } = about;
  return (
    <React.Fragment>
      <HeadingSection
        background={aboutStrength.background}
        heading={aboutStrength.heading}
      />
      <InfoComponentWithImage
        image={aboutStrength.section1.image}
        text={aboutStrength.section1.text}
        heading={aboutStrength.section1.heading}
        direction="left"
      />
      <InfoComponentWithImage
        image={aboutStrength.section2.image}
        text={aboutStrength.section2.text}
        heading={aboutStrength.section2.heading}
        direction="right"
      />
      <Row justify="space-around">
        <Col lg={{ span: 12 }} xs={{ span: 22 }} sm={{ span: 22 }}>
          <HeadingText
            heading={<h1>{aboutStrength.section3.heading}</h1>}
            text={aboutStrength.section3.text}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default AboutStrength;
