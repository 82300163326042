import React from "react";
import content from "../../utils/home";
import { Row, Col, Carousel, Card } from "antd";
import InfoComponentWithImage from "./../common/InfoComponentWithImage/InfoComponentWithImage";
import "./home.css";
import { useHistory } from "react-router";
import ImageArray from "./../common/imageArray/ImageArray";

const { Meta } = Card;

const Home = () => {
  let history = useHistory();
  const {
    background,
    section1,
    section2,
    section3,
    section4,
    section5,
    section6,
  } = content;
  var settings = {
    autoplay: true,
    autoplaySpeed: 3000,
    infinte: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const handleClick = (e) => {
    history.push(e.url);
  };
  return (
    <React.Fragment>
      <Carousel autoplay autoplaySpeed={5000}>
        {background.map((e, i) => {
          return <img src={e} key={i} />;
        })}
      </Carousel>
      <InfoComponentWithImage
        image={section1.image}
        text={section1.text}
        heading={section1.heading}
        direction="left"
      />
      <Row justify="space-around" className="keyPoints">
        {section2.map((e, i) => {
          return (
            <Col
              xs={{ span: 22 }}
              sm={{ span: 22 }}
              lg={{ span: 7 }}
              className="keyPointWrapper"
              key={i}
            >
              <div className="keyPointItem">
                <h2>{e.heading}</h2>
                <h4>{e.text}</h4>
              </div>
            </Col>
          );
        })}
      </Row>
      <Row justify="space-around" className="services">
        <Col span={22}>
          <h1>{section3.heading}</h1>
        </Col>
        {section3.content.map((e, i) => {
          return (
            <Col
              xs={{ span: 22 }}
              sm={{ span: 22 }}
              lg={{ span: 5 }}
              className="servicesItem"
            >
              <h1>{e.icon}</h1>
              <h2>{e.heading}</h2>
              <p>{e.text}</p>
            </Col>
          );
        })}
      </Row>
      <Row justify="space-around" className="services">
        <Col span={23}>
          <h1>{section4.heading}</h1>
          <Carousel {...settings}>
            {section4.content.map((e, i) => {
              return (
                <Card
                  hoverable
                  onClick={() => handleClick(e)}
                  cover={
                    <img
                      key={i}
                      alt={e.name}
                      src={e.img}
                      className="coverImage"
                    />
                  }
                  className="prodCardHome"
                >
                  <Meta title={e.name} />
                </Card>
              );
            })}
          </Carousel>
        </Col>

        <Col span={23}>
          <h1>{section5.heading}</h1>
          <Carousel {...settings}>
            {section5.img.map((e, i) => {
              return <img key={i} alt="" src={e} className="coverImage" />;
            })}
          </Carousel>
        </Col>
      </Row>
      <Row justify="space-around" className="services">
        <Col xs={{ span: 22 }}
              sm={{ span: 22 }}
              lg={{ span: 12 }}
              >
          <h1>{section6.heading}</h1>
          <h3>{section6.text}</h3>
          <ImageArray imgList={section6.img} />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Home;
