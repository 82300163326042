import React from "react";
import { Row, Col } from "antd";
import about from "../../utils/aboutInfo.js";
import HeadingSection from "../common/headingSection/HeadingSection";
import InfoComponentWithImage from "./../common/InfoComponentWithImage/InfoComponentWithImage";
import HeadingText from "./../common/HeadingText/HeadingText";
import ImageArray from "../common/imageArray/ImageArray";
const AboutCompany = () => {
  var { aboutCompany } = about;

  return (
    <React.Fragment>
      <HeadingSection
        background={aboutCompany.background}
        heading={aboutCompany.heading}
      />
      <InfoComponentWithImage
        image={aboutCompany.section1.image}
        text={aboutCompany.section1.text}
        heading={aboutCompany.section1.heading}
        direction="left"
      />
      <Row justify="space-around">
        <Col xs={{ span: 22 }} sm={{ span: 22 }} lg={{ span: 11 }}>
          <HeadingText heading={<h1>{aboutCompany.section2.heading}</h1>}>
            {aboutCompany.section2.list.map((val, i) => {
              return (
                <HeadingText
                  key={i}
                  heading={<h2>{val.heading}</h2>}
                  text={val.text}
                />
              );
            })}
          </HeadingText>
        </Col>
        <Col xs={{ span: 22 }} sm={{ span: 22 }} lg={{ span: 11 }}>
          <HeadingText
            heading={<h1>{aboutCompany.section3.heading}</h1>}
            text={aboutCompany.section3.text}
          />
        </Col>
      </Row>
      <HeadingText heading={<h1>{aboutCompany.section4.heading}</h1>}>
        <ImageArray imgList={aboutCompany.section4.images} />
      </HeadingText>
    </React.Fragment>
  );
};

export default AboutCompany;
