import React, { useState, useEffect } from "react";
import ContactBar from "./ContactBar";
import "./navbar.css";
import { useHistory } from "react-router";
import NavMenu from "./NavMenu";

const Navbar = () => {
  const history = useHistory();
  const handleClick = (e) => {
    history.push(e.keyPath.reverse().join(""));
  };

  return (
    <div>
      <ContactBar />
      <NavMenu onClick={handleClick} />
    </div>
  );
};

export default Navbar;
