import React, { useState, useEffect } from "react";
import { Row, Col, Carousel, Image, Table } from "antd";
import prod from "../../utils/products.json";
import HeadingSection from "./../common/headingSection/HeadingSection";
import "./product.css";
import { useHistory } from "react-router";

const Product = (props) => {
  let [product, setProduct] = useState({});
  let history = useHistory();
  let [columns, setColumns] = useState([]);
  let [data, setData] = useState([]);

  useEffect(() => {
    var category = parseInt(props.match.params.id);
    var tempCategory = prod.products.find((m) => m.id === category);
    if (!tempCategory) history.replace("/not-found");
    var currProduct = tempCategory.products.find(
      (m) => m.name === props.match.params.name
    );
    if (!currProduct) history.replace("/not-found");
    setProduct(currProduct);
    if (currProduct && data.length === 0) {
      var tableHead = currProduct.tableVals[0];
      var keys = Object.keys(tableHead);
      var tempCols = [];
      tempCols = keys.map((v, i) => {
        return { title: tableHead[v], dataIndex: v, key: i };
      });
      setColumns(tempCols);
      var tempData = currProduct.tableVals.splice(1);
      setData(tempData);
    }
  }, [history, props.match.params.id, props.match.params.name, data]);
  return (
    <React.Fragment>
      <HeadingSection background={product.bg} heading={product.name} />
      <Row justify="space-around" className="productContent">
        <Col
          xs={{ span: 22 }}
          sm={{ span: 22 }}
          md={{ span: 22 }}
          lg={{ span: 8 }}
        >
          <Carousel className="productImageCarousel">
            {product.imgList
              ? product.imgList.map((img, i) => {
                  return <Image src={img} key={i} className="productImage" />;
                })
              : ""}
          </Carousel>
        </Col>
        <Col
          xs={{ span: 22 }}
          sm={{ span: 22 }}
          md={{ span: 22 }}
          lg={{ span: 10 }}
        >
          <div className="productDetails">
            <h1 className="textAlign">{product.name}</h1>
            <br />
            <p className="textAlign">{product.text}</p>
            <br />
          </div>
        </Col>
      </Row>
      <Row justify="space-around">
        <Col
          xs={{ span: 22 }}
          sm={{ span: 22 }}
          md={{ span: 22 }}
          lg={{ span: 14 }}
        >
          <h1>Product Description</h1>
          {product.description
            ? product.description.map((v, i) => {
                return <p key={i}>{v}</p>;
              })
            : ""}
        </Col>
        <Col
          xs={{ span: 22 }}
          sm={{ span: 22 }}
          md={{ span: 22 }}
          lg={{ span: 22 }}
        >
          <h1>Technical Details</h1>
        </Col>
        <Col
          xs={{ span: 22 }}
          sm={{ span: 22 }}
          md={{ span: 22 }}
          lg={{ span: 20 }}
        >
          <Table
            dataSource={data}
            columns={columns}
            pagination={{ pageSize: 20 }}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Product;
