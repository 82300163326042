import React from "react";
import { Row, Col } from "antd";
import { PhoneOutlined, EnvironmentOutlined } from "@ant-design/icons";
import logo from "../../Assets/arswan_logo.jpeg";
const contactBarDetails = [
  {
    icon: <PhoneOutlined rotate={90} className="icon" />,
    theme: "outlined",
    offset: 6,
    span: 5,
    rotate: "90",
    heading: "Call Us",
    info: "+91-7020627955, +91-7559491115",
  },
  {
    icon: <EnvironmentOutlined className="icon" />,
    theme: "outlined",
    span: 9,
    offset: 0,
    rotate: "0",
    heading: "Our Address",
    info:
      "S-06, Plot No-2/89, Basweshwar Industrial Complex, Sector 10, PCNTDA, MIDC, Bhosari, Pune, India 411026.",
  },
];

const ContactBar = () => {
  return (
    <Row justify="start" className="contactBar">
      <Col
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        lg={{ span: 4 }}
        className="logoWrapper"
      >
        <img src={logo} className="logo" />
      </Col>
      {contactBarDetails.map((c, i) => {
        return (
          <Col key={i} span={c.span} offset={c.offset} className="contactItem">
            <Row justify="start">
              <Col span={8}>{c.icon}</Col>
              <Col span={16} className="contactItemInfo">
                <h2>
                  <b>{c.heading}</b>
                </h2>
                <h5 className="contactItemInfoDetails">{c.info}</h5>
              </Col>
            </Row>
          </Col>
        );
      })}
    </Row>
  );
};

export default ContactBar;
