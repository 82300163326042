import React from "react";
import "./headingSection.css";
const HeadingSection = ({ background, heading }) => {
  return (
    <div style={{ background: `url(${background})` }} className="headingDiv">
      <h1 className="headingText">{heading}</h1>
    </div>
  );
};

export default HeadingSection;
