import React from "react";
import { Row, Col } from "antd";
import about from "../../utils/aboutInfo";
import HeadingSection from "../common/headingSection/HeadingSection";
import InfoComponentWithImage from "./../common/InfoComponentWithImage/InfoComponentWithImage";
import HeadingText from "./../common/HeadingText/HeadingText";
const AboutQuality = () => {
  const { aboutQuality } = about;
  return (
    <React.Fragment>
      <HeadingSection
        background={aboutQuality.background}
        heading={aboutQuality.heading}
      />
      <InfoComponentWithImage
        image={aboutQuality.section1.image}
        text={aboutQuality.section1.text}
        heading={aboutQuality.section1.heading}
        direction="left"
      />
      <InfoComponentWithImage
        image={aboutQuality.section2.image}
        text={aboutQuality.section2.text}
        heading={aboutQuality.section2.heading}
        direction="right"
      />
      <Row justify="space-around">
        <Col lg={{ span: 22 }} xs={{ span: 22 }} sm={{ span: 22 }}>
          <HeadingText
            heading={<h1>{aboutQuality.section3.heading}</h1>}
            text={aboutQuality.section3.text}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default AboutQuality;
