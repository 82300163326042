// import logo from "./logo.svg";
import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import AboutCompany from "./components/about/AboutCompany";
import AboutStrength from "./components/about/AboutStrength";
import AboutQuality from "./components/about/AboutQuality";
import AboutService from "./components/about/AboutService";
import Home from "./components/home/Home";
import Product from "./components/Products/Product";
import Products from "./components/Products/Products";
import NotFound from "./components/notFound/NotFound";
import Footer from "./components/footer/Footer";
import Contact from "./components/contact/Contact";
class App extends Component {
  state = {};
  render() {
    return (
      <div className="App">
        <Navbar />
        <div>
          <Switch>
            <Route path="/home" component={Home} />
            <Route path="/about/company" component={AboutCompany} />
            <Route path="/about/strength" component={AboutStrength} />
            <Route path="/about/quality" component={AboutQuality} />
            <Route path="/about/service" component={AboutService} />

            <Route
              path="/products/:id"
              render={(props) => <Products {...props} />}
            />
            <Route path="/products/" component={Products} />
            <Route
              path="/product/:id/:name"
              render={(props) => <Product {...props} />}
            />
            <Route path="/contact" component={Contact} />
            <Route path="/not-found" component={NotFound} />
            <Redirect from="/" exact to="/home" />
            <Redirect to="/not-found" />
          </Switch>
        </div>
        <Footer />
      </div>
    );
  }
}

export default App;
