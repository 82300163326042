import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "antd";
import prod from "../../utils/products.json";
import HeadingSection from "./../common/headingSection/HeadingSection";
import "./products.css";
import { useHistory } from "react-router";
const { Meta } = Card;

const Products = (props) => {
  let [prodCategory, setCategory] = useState("");
  let history = useHistory();
  useEffect(() => {
    setCategory(
      prod.products.find((m) => m.id === parseInt(props.match.params.id))
    );
  }, [props.match.params.id]);

  const handleClick = (e) => {
    history.replace("/product/" + props.match.params.id + "/" + e.name);
  };
  return (
    <React.Fragment>
      {/* <h1>hello</h1> */}
      <HeadingSection
        background={prodCategory.img}
        heading={prodCategory.category}
      />
      <Row justify="space-around">
        <Col span={22}>
          <Row justify="space-around">
            {prodCategory.products
              ? prodCategory.products.map((p, i) => {
                  return (
                    <Col
                      key={i}
                      xs={{ span: 22 }}
                      sm={{ span: 22 }}
                      md={{ span: 11 }}
                      lg={{ span: 7 }}
                    >
                      <Card
                        hoverable
                        onClick={() => handleClick(p)}
                        cover={
                          <img
                            alt={p.name}
                            src={p.img}
                            className="coverImage"
                          />
                        }
                        className="prodCard"
                      >
                        <Meta
                          title={p.name}
                          description={
                            p.text.length > 200
                              ? p.text.substr(0, 200) + "..."
                              : p.text
                          }
                        />
                      </Card>
                    </Col>
                  );
                })
              : ""}
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Products;
