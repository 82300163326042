import React from "react";
import { Row, Col, Card, Image } from "antd";
const ImageArray = (props) => {
  return (
    <React.Fragment>
      <Row justify="space-around">
        {props.imgList.map((img, i) => {
          return (
            <Col
              key={i}
              xs={{ span: 22 }}
              sm={{ span: 22 }}
              lg={{ span: parseInt(22 / props.imgList.length) }}
            >
              <Image src={img} />
            </Col>
          );
        })}
      </Row>
    </React.Fragment>
  );
};

export default ImageArray;
