import React from "react";
import data from "../../utils/contact.json";
import HeadingSection from "./../common/headingSection/HeadingSection";
import { Row, Col } from "antd";
import Map from "./Map";

const Contact = () => {
  return (
    <React.Fragment>
      <HeadingSection background={data.bg} heading={data.name} />
      <Row justify="space-around" style={{ marginTop: "20px" }}>
        <Col
          xs={{ span: 22 }}
          sm={{ span: 22 }}
          md={{ span: 22 }}
          lg={{ span: 16 }}
        >
          <Map />
        </Col>
        <Col
          xs={{ span: 22 }}
          sm={{ span: 22 }}
          md={{ span: 22 }}
          lg={{ span: 16 }}
        >
          <h2>Contact Us</h2>
          <h3>
            S-06, Plot No-2/89, Basweshwar Industrial Complex, Sector 10,
            PCNTDA, MIDC, Bhosari, Pune, India 411026.
          </h3>
          <h3>Mobile: +91-7020627955, +91-7559491115</h3>
          <h3>Email: arswansales@gmail.com, arswan.marketing@gmail.com</h3>
          <h3>WeChat: kabhi07</h3>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Contact;
