export default {
  aboutCompany: {
    background: "//v4-upload.goalsites.com/312/image_1597625465_3.jpg",
    heading: "About Us",
    section1: {
      image:
        "https://upload.digoodcms.com/312/image_1564206298_D37A9318.JPG?imageView2/1/w/600/h/580",
      heading: "About YLSK",
      text: [
        "YLSK located in Dongguan City which is hub of high-tech enterprise & top manufacturing Industries.Nearly 15000 square meters of R&D, Production Assembly factory in Dongguan City and has nearly 5,000 square meters of assembly parts production workshops in shaoguan & huizhou.",
        " YLSK has established a perfect sales and service network, and exported to Vietnam, Egypt, Burma, the Middle East, southeast Asia and other countries and regions. We wholeheartedly welcome interested parties to be part of YLSK machinery's ever expanding family. The company have a young and energetic team, and providing uninterrupted service to customers in 24 hours.",
        " Looking forward to the future, YLSK wishes rapid development with constant commitment, high quality and stable products, quick and considerate service to realize win-win situation with customers, we are striving to build the first brand in the spring industry. ",
        "We uphold ”Honesty, Responsibility, Prudently, Technological Advancement” as principle, and lead our brand go to the future and go to the world. ",
        "Converting advanced technologies into productive forces requires advanced production and processing capabilities. At the same time, equipment standardization is an important guarantee to enhance the international competitiveness and strong foundation to achieve “Excellent Quality ”.",
      ],
    },
    section2: {
      heading: "Business Philosophy",
      text: [],
      list: [
        {
          heading: "Vision",
          text: [
            "To be the leader in the manufacturing of high precision,high quality spring machines.",
          ],
        },
        {
          heading: "Mission",
          text: [
            "Offer quality products on reasonable price and ensure the best service base on environmental responsibility to enhance customer satisfaction.",
          ],
        },
        {
          heading: "Corporate Policy",
          text: [
            "YLSK spring machines always takes highest concern of stakeholders such as customers,suppliers,employees,community and others continuous prompted improvement,stressing on the following 7 elements:",
            " Quality, Cost, Delivery, Safety, Morable, Ethics, Environment.",
          ],
        },
      ],
    },
    section3: {
      heading: "Our Policy",
      text: [
        "Guangdong Yonglian CNC Equipment Technology Co., Ltd. (YLSK) is committed to achieve customer satisfaction by delivering products and services with compliance of all applicable requirements that meet or exceed customer expectations on quality and delivery.",
        "We strive to continually improve our processes and systems to ensure enhanced quality, optimized cost and faster throughput. In our quest for excellence, we lay emphasis on achievement of organizational objectives through teamwork, training and innovation.",
      ],
    },
    section4: {
      heading: "Our Factory",
      images: [
        "https://upload.digoodcms.com/312/image_1564208296_D37A9303.JPG?imageView2/1/w/600/h/280",
        "https://upload.digoodcms.com/312/image_1564208308_D37A9306.JPG?imageView2/1/w/600/h/280",
        "https://upload.digoodcms.com/312/image_1564208317_D37A9309.JPG?imageView2/1/w/600/h/280",
        "https://upload.digoodcms.com/312/image_1564208325_D37A9311.JPG?imageView2/1/w/600/h/280",
      ],
    },
  },
  aboutStrength: {
    background: "//v4-upload.goalsites.com/312/image_1597625465_3.jpg",
    heading: "Our Strength",
    section1: {
      heading: "We Work Together",
      image: "https://v4-upload.goalsites.com/312/1564129953_team.jpg",
      text: [
        "Creating partnerships is a key success factor for the YLSK Group.",
        "We want to create long-term business relationships based on mutual trust and respect by developing synergies that reinforce our competitive position, as well as that of our customers, in reference markets.",
        "We work together with customers and suppliers in product development to ensure that the service we provide is excellent.",
        " We wish to be known as a reliable partner for all parties with whom we interact.",
      ],
    },
    section2: {
      heading: "We Stimulate Innovation",
      image: "https://v4-upload.goalsites.com/312/1564130190_work2.jpg",
      text: [
        "Constant renewal has always been a vital aspect of our Company.",
        "We promote innovation and the exchange of ideas with our customers, suppliers, technicians, collaborators and research centres.",
        " We always take advantage of innovative work methodologies.",
        "We adopt a long-term approach in order to foresee business trends, across all company areas, seize future opportunities, and ensure the success of our Group.",
        "We know how to adapt quickly to new requirements, and we strive to develop innovation and know how",
      ],
    },
    section3: {
      heading: "We Praise Knowledge",
      text: [
        "Vast industry experience and deep knowledge of the field. Rugged and sturdy infrastructure outfitted with latest designed machinery and equipment's. Productive and experienced professionals comprising the workforce. Technology driven manufacturing techniques and working methodologies.",
        "Strict and stringent quality control system. Gigantic stock of machines to serve emergency demands and supply. Active transportation and packaging department. Quality Machines at competitive price.",
      ],
    },
  },
  aboutQuality: {
    background: "//v4-upload.goalsites.com/312/image_1597625465_3.jpg",
    heading: "Quality Control",
    section1: {
      heading: "Quality Control",
      image: "https://v4-upload.goalsites.com/312/1564132542_3.jpg",
      text: [
        "YLSK Company’s customer satisfaction is driven by their stringent Quality Management System which assures the product of the highest standards. We are committed to the manufacture and delivery of high-quality Machines. It is our goal to supply products and services which meet and/or exceed the needs of our customers. This is accomplished through a preventative systems approach toward Quality Assurance. Constant improvement in production process and increase productivity.",
      ],
    },
    section2: {
      heading: "Quick Deliveries",
      image: "https://v4-upload.goalsites.com/312/1564132618_4.jpg",
      text: [
        "Guangdong Yonglian CNC Equipment Tech. Co., Ltd., (YLSK) takes pride in the fact that we offer quick deliveries on all of our orders with quantity capabilities ranging number of machines. Quotes, on the other hand, Inquiries are typically answered within minutes rather than days. For those customers interested in the product with production volume capacities, YLSK Spring Machines offers complete research and development leading up to Machine manufacturing.",
      ],
    },
    section3: {
      heading: "Code of Ethics:",
      text: [
        "Since our inception we have always conducted business with a moral responsibility in tandem with a conscious driven approach. We are truest to our values we nurture and accountable to all our Customers, worldwide Business Partners in maintaining and increasing their wealth.",
      ],
    },
  },
  aboutService: {
    background: "//v4-upload.goalsites.com/312/image_1597625465_3.jpg",
    heading: "Customer Service",
    section1: {
      text: [
        "YLSK machinery's Customer service can be considered as its IDENTITY. Here we believe that a satisfied customer is an invaluable asset and clearly reflects the true identity of a company. We make it a priority to understand the needs of our customer and accordingly provide the relevant machine for their tools.",
      ],
    },
    section2: {
      heading:
        "We had distinctly divided our customer service in to Three parts",
    },
    section3: {
      list: [
        {
          heading: "BEFORE SALES SERVICE:",
          text: [
            "This Phase of service is provided by our Sales & Marketing team, where we hear our client requirements.  We understand the needs of our clients, the specification they need, the spring they want to produce, the time frame in which they want us to provide the machine etc.  Our executives will than make a suggestion based on the requirements. While making the suggestion we make it a point that we provide the EXACT MACHINE that our client need in terms of SPECIFICATION and COST.",
          ],
        },
        {
          heading: "DURING SALES SERVICE:",
          text: [
            "When the client is satisfied with the Specification, Quality and Cost, our Sales executive will recheck the package to make sure all the accessories and manuals are in place, the cargo is safely packed. This we call it “PERSONAL TOUCH”.  If the client want us to assist with the cargo, we readily do it and help the client find the best and the cheapest way of delivery.  At the same time we keep a track of the cargo and keep our client updated about it. Though most of our models are very easy to operate, yet we also provide the Operation classes online for those clients, who find it hard to operate.",
          ],
        },
        {
          heading: "AFTER SALES SERVICE:",
          text: [
            "After sales service is the most important criteria to prove a good and reliable customer service. So here in YLSK machinery, we stick with our clients way beyond the warranty period. We had always considered that our machine speaks of our quality and our after sales service speaks of our attitude. This service is provided by our engineers. Though our engineers can speak English, yet, if there is any linguistic problem we take the assistance of our multilingual sales team to communicate. ",
          ],
        },
      ],
    },
  },
};
