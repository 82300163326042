import React from "react";
import "./infoComponent.css";
import { Row, Col } from "antd";
const InfoComponentWithImage = (props) => {
  return (
    <React.Fragment>
      <div className="heading">{props.heading}</div>
      {props.direction === "left" ? (
        <Row justify="space-around">
          <Col xs={{ span: 22 }} sm={{ span: 22 }} lg={{ span: 10 }}>
            <img src={props.image} className="infoImage" />
          </Col>
          <Col
            xs={{ span: 22 }}
            sm={{ span: 22 }}
            lg={{ span: 10 }}
            className="infoText"
          >
            {props.text
              ? props.text.map((t, i) => {
                  return <p key={i}>{t}</p>;
                })
              : ""}
          </Col>
        </Row>
      ) : (
        <Row justify="space-around">
          <Col
            xs={{ span: 22 }}
            sm={{ span: 22 }}
            lg={{ span: 10 }}
            className="infoText"
          >
            {props.text
              ? props.text.map((t, i) => {
                  return <p key={i}>{t}</p>;
                })
              : ""}
          </Col>
          <Col xs={{ span: 22 }} sm={{ span: 22 }} lg={{ span: 10 }}>
            <img src={props.image} className="infoImage" />
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

export default InfoComponentWithImage;
