import React, { Component } from "react";
import { withGoogleMap, GoogleMap } from "react-google-maps";

class Map extends Component {
  render() {
    const GoogleMapExample = withGoogleMap((props) => (
      <GoogleMap
        defaultCenter={{ lat: 18.6498856, lng: 73.8351242 }}
        defaultZoom={16}
      ></GoogleMap>
    ));
    return (
      <div>
        <GoogleMapExample
          containerElement={<div style={{ height: "500px", width: "100%" }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>
    );
  }
}
export default Map;
