import React from "react";
import { Row, Col } from "antd";
import about from "../../utils/aboutInfo";
import HeadingSection from "../common/headingSection/HeadingSection";
import HeadingText from "./../common/HeadingText/HeadingText";
const AboutService = () => {
  const { aboutService } = about;
  return (
    <React.Fragment>
      <HeadingSection
        background={aboutService.background}
        heading={aboutService.heading}
      />
      <Row justify="space-around">
        <Col lg={{ span: 22 }} xs={{ span: 22 }} sm={{ span: 22 }}>
          <HeadingText text={aboutService.section1.text} heading={""} />
        </Col>
        <Col lg={{ span: 22 }} xs={{ span: 22 }} sm={{ span: 22 }}>
          <HeadingText heading={<h2>{aboutService.section2.heading}</h2>} />
        </Col>
        {aboutService.section3.list.map((val, i) => {
          return (
            <Col key={i} lg={{ span: 22 }} xs={{ span: 22 }} sm={{ span: 22 }}>
              <HeadingText heading={<h3>{val.heading}</h3>} text={val.text} />
            </Col>
          );
        })}
      </Row>
    </React.Fragment>
  );
};

export default AboutService;
