import React, { useState } from "react";
import { Menu, Button } from "antd";
import prod from "../../utils/products.json";
import { MenuOutlined } from "@ant-design/icons";
const { SubMenu } = Menu;

let prodSubroutes = prod.products.map((val) => {
  return { name: val.category, url: "/" + val.id };
});

const navbarInfo = [
  {
    name: "Home",
    url: "/home",
  },
  {
    name: "About Us",
    url: "/about",
    submenu: [
      {
        name: "Company Profile",
        url: "/company",
      },
      {
        name: "Our Strength",
        url: "/strength",
      },
      {
        name: "Quality Control",
        url: "/quality",
      },
      {
        name: "Customer Service",
        url: "/service",
      },
    ],
  },
  {
    name: "Products",
    url: "/products",
    submenu: prodSubroutes,
  },
  {
    name: "Contact Us",
    url: "/contact",
  },
];

const NavMenu = ({ onClick }) => {
  var [top, setTop] = useState(0);
  var [collapse, setCollapse] = useState(true);
  var [width, setWidth] = useState(window.innerWidth);
  window.addEventListener("scroll", () => {
    setTop(window.pageYOffset);
  });

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });
  const handleSticky = () => {
    return top > 100 ? " sticky" : "";
  };
  const handleClick = () => {
    setCollapse(!collapse);
  };
  return (
    <React.Fragment>
      {width < 768 ? (
        <Button type="primary" onClick={handleClick} className="mobileMenuBtn">
          <MenuOutlined />
        </Button>
      ) : (
        ""
      )}
      <Menu
        onClick={onClick}
        mode={width >= 768 ? "horizontal" : "vertical-left"}
        className={"mainMenu" + handleSticky()}
        style={{
          display: width >= 768 ? "block" : collapse ? "none" : "block",
        }}
        theme="dark"
        inlineCollapsed={width >= 768 ? false : collapse}
      >
        {navbarInfo.map((val, i) => {
          return (
            <React.Fragment>
              {val.submenu ? (
                <SubMenu key={val.url} className="menuItem" title={val.name}>
                  {val.submenu.map((v1, j) => {
                    return (
                      <Menu.Item key={v1.url} className="menuItem">
                        {v1.name}
                      </Menu.Item>
                    );
                  })}
                </SubMenu>
              ) : (
                <Menu.Item key={val.url} className="menuItem">
                  {val.name}
                </Menu.Item>
              )}
            </React.Fragment>
          );
        })}
      </Menu>
    </React.Fragment>
  );
};

export default NavMenu;
