import React from "react";
import "./headingText.css";
const HeadingText = (props) => {
  return (
    <React.Fragment>
      <div className="headingValue">{props.heading}</div>
      {props.text
        ? props.text.map((t, i) => {
            return (
              <div key={i} className="contentValue">
                {t}
              </div>
            );
          })
        : ""}

      {props.children}
    </React.Fragment>
  );
};

export default HeadingText;
