import {
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";

export default [
  {
    icon: <FacebookOutlined />,
    url: "https://www.facebook.com/arswan.corp",
  },
  {
    icon: <TwitterOutlined />,
    url: "https://www.twitter.com",
  },
  {
    icon: <InstagramOutlined />,
    url: "https://www.instagram.com/arswan07",
  },
  {
    icon: <LinkedinOutlined />,
    url: "https://www.linkedin.com",
  },
  {
    icon: <YoutubeOutlined />,
    url: "https://www.youtube.com/channel/UCl18TV9mqWR4fb6gEEml8rg",
  },
];
