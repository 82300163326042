import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";
import logo from "../../Assets/arswan_logo.jpeg";
import SocialMedia from "../../utils/socialMedia";
import prod from "../../utils/products.json";

let prodSubroutes = prod.products.map((val) => {
  return { name: val.category, url: "/products/" + val.id };
});

const Footer = () => {
  return (
    <React.Fragment>
      <Row
        justify="space-around"
        style={{
          marginTop: "20px",
          background: "#001529",
          color: "white",
          paddingTop: "20px",
        }}
      >
        <Col
          xs={{ span: 22 }}
          sm={{ span: 22 }}
          md={{ span: 22 }}
          lg={{ span: 4 }}
          //   style={{ paddingTop: "20px" }}
        >
          <img src={logo} alt="Arswan" className="logo" />
          <Row justify="space-around" style={{ paddingTop: "20px" }}>
            {SocialMedia.map((v, i) => {
              return (
                <Col key={i}>
                  <a href={v.url}>{v.icon}</a>
                </Col>
              );
            })}
          </Row>
        </Col>
        <Col
          xs={{ span: 22 }}
          sm={{ span: 22 }}
          md={{ span: 22 }}
          lg={{ span: 6 }}
        >
          <h2 style={{ color: "white" }}>Our Products</h2>
          {prodSubroutes.map((v, i) => {
            return (
              <div key={i}>
                <h3>
                  <Link to={v.url}>{v.name}</Link>
                </h3>
              </div>
            );
          })}
        </Col>
        <Col
          xs={{ span: 22 }}
          sm={{ span: 22 }}
          md={{ span: 22 }}
          lg={{ span: 6 }}
        >
          <h2 style={{ color: "white" }}>Contact Us</h2>
          <h3 style={{ color: "white" }}>
            Mobile: +91-7020627955, +91-7559491115
          </h3>
          <h3 style={{ color: "white" }}>
            Email: arswansales@gmail.com, arswan.marketing@gmail.com
          </h3>
        </Col>
      </Row>
      <Row justify="space-around">
        <h2>Copyright @ Arswan CNC Machine 2021. All Rights Reserved</h2>
      </Row>
    </React.Fragment>
  );
};

export default Footer;
